.logout {
  border-radius: 10px;
  .centerImage {
    display: block;
    margin: 0 auto;
  }

  .centerButton {
    display: block;
    margin: 10px auto;
  }

  .ant-btn {
    width: 150px !important;
  }

  .ant-drawer-content {
    background-color: "#E5F7F8" !important;
  }
}
.ant-drawer-content {
  background-color: "#E5F7F8" !important;
}

.ant-drawer .ant-drawer-content {
  border-radius: 5px;
  // right: 10px;
}

@primary-color: #05529a;@border-radius-base: 3px;@card-radius: 6px;@select-item-selected-bg: #beeaffd4;