@input-border-radius: @border-radius-base;
@input-bg-color: @white;
@input-border-color: #d9d9d9;

.ant-input-affix-wrapper {
  border-radius: @input-border-radius;
  background-color: @input-bg-color;
  border-color: @input-border-color;

  input {
    background-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: @input-bg-color;
  }
}

.ant-input {
  background-color: @input-bg-color;
  border-radius: @input-border-radius;
  border-color: @input-border-color;
}

.ant-form-item-explain {
  font-size: @font-size-base - 2px;
  z-index: 1;
}
