@import "./variables.less";
@import "./flex.less";
@import "./spacing.less";
@import "./font.less";
// @import "./display.less";

.pointer {
  cursor: pointer;
}

.bg-primary {
  background-color: @primary-color;
}
.bg-info {
  background-color: @info-color;
}
.bg-success {
  background-color: @success-color;
}
.bg-error {
  background-color: @error-color;
}

.bg-warning {
  background-color: @warning-color;
}
