@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "./additional-variables.less";
@import "../layouts/index.less";
@import "~bootstrap-utilities";
@import "../utilities/index.less";
@import "../components/index.less";
@import "./base-variables.less";
@import "../pages/index.less";
@import "../typography.less";

// Layout
@layout-header-background: @primary-color;
@layout-text-color: @white;

:root {
  --primary-color: @primary-color;
  --light-color: @light-color;
  --required-color: @red-color;
  --grey-color-100: @grey-color-100;
}

// Sidebar
@sidebar-bg-color: @white;
@sidebar-text-color: @black;

@sidebar-submenu-color: @black;
@sidebar-submenu-hover-color: @black;
@sidebar-submenu-hover-bg-color: @light-color;
@sidebar-submenu-active-color: @primary-color;
@sidebar-submenu-active-bg-color: @white;
@sidebar-submenu-icon-color: #666666;
@sidebar-submenu-icon-hover-color: #666666;
@sidebar-submenu-icon-active-color: @primary-color;

@sidebar-menu-item-color: @black;
@sidebar-menu-item-hover-color: @black;
@sidebar-menu-item-icon-color: #666666;
@sidebar-menu-item-icon-hover-color: #666666;
@sidebar-menu-item-bg-color: @light-color;
@sidebar-menu-item-hover-bg-color: @light-color;
@sidebar-menu-item-color: @black;
@sidebar-menu-item-active-color: @primary-color;
@sidebar-menu-item-icon-color: @black;
@sidebar-menu-item-icon-active-color: @primary-color;
@sidebar-menu-item-icon-hover-color: @black;

// Input
@input-placeholder-color: #7d7d7d;
@disabled-color: #303030;

// Button
@btn-default-color: #202223;

// Tooltip
@tooltip-bg: @white;
@tooltip-color: @black;

// Table
@table-selected-row-bg: @light-color;

@primary-color: #05529a;@border-radius-base: 3px;@card-radius: 6px;@select-item-selected-bg: #beeaffd4;