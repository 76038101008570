.title-spacing {
  margin-top: 0.5em !important;
  text-transform: capitalize;
  padding-left: 0.2em !important;
}

@media (min-width: 280px) {
  .header-row-content {
    justify-content: space-between;
    align-items: center;
    height: 64px !important;
  }
}

@media (min-width: 320px) {
  .header-row-content {
    justify-content: space-between;
    align-items: center;
    height: 64px !important;
  }
}

@media (min-width: 480px) {
  .digitize-header-title {
    font-size: 1em !important;
  }
}

@media (min-width: 540px) {
  .digitize-header-title {
    font-size: 0em !important;
  }
}

@media (min-width: 600px) {
  .digitize-header-title {
    font-size: 1.3em !important;
  }
}

@media screen and (min-width: 768px) {
  .digitize-header-title {
    font-size: 1.5em !important;
  }
}

@media screen and (min-width: 992px) {
  .digitize-header-title {
    font-size: 2em !important;
  }
}
