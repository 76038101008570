.gen-flex(5);

.gen-flex(@n, @i: 1) when (@i =< @n) {
  .flex-@{i} {
    flex: @i;
  }
  .gen-flex(@n, (@i + 1));
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

@direction: row, row-reverse, column, column-reverse;
@align: flex-start, flex-end, center, baseline, stretch;

.make-flex-props(@prefix, @property-key, @class-name, @property-arr, @i: 1)
  when
  (@i =< length(@property-arr)) {
  @property: extract(@property-arr, @i);

  .@{class-name}-@{property}@{prefix} {
    @{property-key}: @property !important;
  }

  .make-flex-props(@prefix, @property-key, @class-name, @property-arr, (@i + 1));
}

.make-flex-props(~"", ~"flex-direction", ~"direction", @direction);
.make-flex-props(~"", ~"align-items", ~"align", @align);

@media (max-width: calc(@screen-sm-min - 1px)) {
  .make-flex-props(-sm, ~"flex-direction", ~"direction", @direction);
  .make-flex-props(-sm, ~"align-items", ~"align", @align);
}

@media (max-width: calc(@screen-md-min - 1px)) {
  .make-flex-props(-md, ~"flex-direction", ~"direction", @direction);
  .make-flex-props(-md, ~"align-items", ~"align", @align);
}

@media (max-width: calc(@screen-lg-min - 1px)) {
  .make-flex-props(-lg, ~"flex-direction", ~"direction", @direction);
  .make-flex-props(-lg, ~"align-items", ~"align", @align);
}

@media (max-width: calc(@screen-xl-min - 1px)) {
  .make-flex-props(-xl, ~"flex-direction", ~"direction", @direction);
  .make-flex-props(-xl, ~"align-items", ~"align", @align);
}
