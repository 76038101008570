:root {
  --color-title: #dd0000;
  --color-bg: #dd0000;
}
.cursor-pointer {
  cursor: pointer;
}

.border-bottom-1 {
  border-bottom: 1px solid #c9cccf !important;
}

.ant-checkbox-group-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-top: 0.8em;
}

.mg-err .ant-form-item-with-help {
  margin-bottom: 24px !important;
}

.ant-checkbox + span {
  padding-right: 0.5em;
  padding-left: 0.8em;
  padding-top: 0.5em;
}

.ant-popover-arrow {
  border-style: none;
  height: 0 !important;
  width: 0 !important;
}
.ant-menu-item a:hover {
  color: #dd0000;
}
.custom-color-btn:hover,
.custom-color-btn:focus {
  color: #dd0000;
  border-color: #dd0000;
}
.popover-colors {
  overflow-x: hidden !important;
  overflow: scroll;
  background-color: #ffffff;
}

.upload-img {
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: fill;
  }
}

.mg-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.h-42 {
  height: 42px;
}

.h-50 {
  height: 50px;
}
.ant-spin-dot-item {
  background-color: #dd0000;
}
.title-color {
  color: var(--color-title) !important;
}
.border-blue .ant-btn:hover {
  border-color: #3699ff;
}
.ant-btn:hover {
  color: var(--color-title);
  border-color: var(--color-title);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--color-title);
}
@media screen and(max-width: 480px) {
  .ant-mobile {
    padding-top: 1.5rem !important;
  }
}

.bar-code-wrap-form-right-column {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
  margin-bottom: 24px;

  @media screen and (min-width: 992px) and (max-width: 1600px) {
    flex-direction: column;
  }
}
