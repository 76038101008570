#detaiPhongs {
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: #000 !important;
  }
  .ant-input[disabled] {
    color: #000;
    // font-weight: 300;
  }
}

@indentation: 24px;
@normal-text-size: 16px;
@title-text-size: 18px;
@code-text-size: 22px;

.indentation {
  padding-left: 24px;
  padding-right: 24px;
}

.handover-form-wrapper {
  .handover-form {
    margin: auto;
    // width: 1080px;
    overflow: auto;
    @media screen and (max-width: 1599px) {
      width: 100%;
    }

    h1,
    h2,
    h3,
    span,
    p,
    input,
    textarea {
      font-size: @normal-text-size;
      line-height: calc(@normal-text-size + 4px);
      margin-bottom: 8px;
    }

    .ant-picker input::-webkit-input-placeholder {
      font-size: 18px;
      font-family: Roboto;
    }

    .ant-picker .ant-picker-suffix {
      display: flex;
      width: 18px;
    }

    input,
    .ant-picker {
      padding: 2px 2px;
      margin-bottom: 0;
    }

    .ant-picker span {
      margin: 0;
    }

    .ant-picker .anticon {
      display: flex;
      align-items: center;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 @indentation;
    }

    .code {
      text-align: right;
      margin-bottom: 2px;
      font-size: @code-text-size;
      font-style: italic;
    }

    .left-side p {
      font-size: 16px !important;
      text-align: center;
    }

    .right-side {
      width: 65%;
    }

    .national-title-slogan {
      display: flex;
      flex-direction: column;
    }

    .national-title {
      text-align: center;
      margin-bottom: 0px;
    }

    .national-slogan {
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      margin: auto;
      border-bottom: 2px solid black;
      justify-self: left;
    }

    .handover-current-place-date {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }

    .handover-current-place {
      margin-right: 12px;
      width: 240px;
      border: 1px solid #d9d9d9;
    }
    .handover-current-date {
      width: 240px;
    }
    &-content {
      margin-top: 20px;
    }
    &-title-content {
      text-align: center;
    }

    &-main-content {
      padding: 0;
    }

    .title-paragraph {
      text-indent: @indentation;
    }

    .title-item {
      margin-bottom: 0;
    }

    .content-item {
      padding: 0 @indentation;
      margin-bottom: 0;
    }

    @media (max-width: 1399px) {
      h1,
      h2,
      h3,
      span,
      p,
      input,
      textarea {
        font-size: calc(@normal-text-size - 2px);
        line-height: calc(@normal-text-size + 4px);
      }

      h1.title {
        font-size: calc(@title-text-size - 2px);
      }

      h2.sub-title {
        font-size: calc(@normal-text-size - 2px);
      }

      h1.title {
        font-size: calc(@title-text-size - 3px);
      }

      .handover-current-place,
      .handover-current-date {
        width: 40%;
      }

      .content-item {
        padding: 0 calc(@indentation - 24px);
      }
      .title-paragraph {
        text-indent: calc(@indentation - 24px);
      }
      .handover-form-header {
        padding: 0 calc(@indentation - 24px);
      }
    }

    @media (max-width: 1199px) {
      h1,
      h2,
      h3,
      span,
      p,
      input,
      textarea {
        font-size: calc(@normal-text-size - 3px);
        line-height: calc(@normal-text-size + 4px);
      }

      h1.title {
        font-size: calc(@title-text-size - 3px);
      }

      h2.sub-title {
        font-size: calc(@normal-text-size - 3px);
      }

      .content-item {
        padding: calc(@indentation - 48px);
        width: 95%;
      }
      .title-paragraph {
        text-indent: calc(@indentation - 48px);
      }
      .handover-form-header {
        padding: calc(@indentation - 48px);
      }
    }
    @media (max-width: 1023px) {
      .content-item {
        width: 100%;
      }
    }
    @media (max-width: 767px) {
      .signal-area {
        h1.title {
          font-size: calc(@title-text-size - 4px);
        }
      }
    }

    @media (max-width: 599px) {
      h1,
      h2,
      h3,
      span,
      p,
      input,
      textarea {
        font-size: calc(@normal-text-size - 6px);
        line-height: calc(@normal-text-size + 2px);
      }

      h1.title {
        font-size: calc(@title-text-size - 6px);
      }
      h2.sub-title {
        font-size: calc(@normal-text-size - 6px);
      }

      .code {
        font-size: calc(@code-text-size - 4px);
      }

      .left-side p {
        font-size: 14px !important;
      }

      .signal-area {
        h1.title {
          font-size: calc(@title-text-size - 6px);
        }
      }
    }
  }
}

.determine-form-wrapper {
  .determine-form {
    margin: auto;
    // width: 1080px;
    overflow: auto;
    @media screen and (max-width: 1599px) {
      width: 100%;
    }
    h1,
    h2,
    h3,
    span,
    p,
    input,
    textarea {
      font-size: @normal-text-size;
      line-height: calc(@normal-text-size + 4px);
      margin-bottom: 8px;
    }

    .ant-picker input::-webkit-input-placeholder {
      font-size: 18px;
      font-family: Roboto;
    }

    input,
    .ant-picker {
      padding: 2px 2px 2px 4px;
      margin-bottom: 0;
    }

    .ant-picker .ant-picker-suffix {
      display: flex;
      width: 18px;
    }

    .ant-picker span {
      margin: 0;
    }

    .ant-picker .anticon {
      display: flex;
      align-items: center;
    }

    textarea {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    h1.header-title {
      font-weight: 700;
      font-size: @title-text-size;
    }

    h1.title {
      font-weight: 700;
      font-size: calc(@title-text-size - 2px);
    }

    .sub-title {
      font-size: calc(@normal-text-size - 2px);
    }

    h2.header-sub-title {
      font-size: @normal-text-size;
      font-weight: 700;
    }

    .code {
      text-align: right;
      margin-bottom: 2px;
      font-size: 22px;
      font-style: italic;
    }
    &-header {
      margin: auto;
    }

    &-main-content {
      padding: 0;
    }

    .title-paragraph {
      text-indent: @indentation;
    }

    .title-item {
      margin-bottom: 0;
    }

    .content-item {
      padding: 0 0 0 @indentation;
      margin-bottom: 0;
    }

    .determine-current-place-date {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }

    .determine-current-place {
      margin-right: 12px;
      width: 240px;
      border: 1px solid #d9d9d9;
    }
    .determine-current-date {
      width: 240px;
    }

    .first-sign {
      input {
        max-width: 210px;
      }
    }

    // @media (max-width: 1199px) {
    //   h1,
    //   h2,
    //   h3,
    //   span,
    //   p,
    //   input,
    //   textarea {
    //     font-size: calc(@normal-text-size - 2px);
    //     line-height: calc(@normal-text-size + 4px);
    //   }
    //   h1.title {
    //     font-size: calc(@title-text-size - 2px);
    //   }
    //   .content-item {
    //     padding: 0 calc(@indentation - 36px) 0 calc(@indentation - 36px);
    //     margin-bottom: 0;
    //   }
    //   .title-paragraph {
    //     text-indent: calc(@indentation - 36px);
    //   }
    // }
  }
}

.classify-create-file-form-wrapper {
  .classify-create-file-form {
    margin: auto;
    // width: 1080px;
    @media screen and (max-width: 1599px) {
      width: 100%;
    }
    h1,
    h2,
    h3,
    span,
    p,
    input,
    textarea {
      font-size: @normal-text-size;
      line-height: calc(@normal-text-size + 4px);
      margin-bottom: 8px;
    }

    .ant-picker input::-webkit-input-placeholder {
      font-size: 18px;
      font-family: Roboto;
    }

    input,
    .ant-picker {
      padding: 2px 2px 2px 4px;
      margin-bottom: 0;
    }

    .ant-picker .ant-picker-suffix {
      display: flex;
      width: 18px;
    }

    .ant-picker span {
      margin: 0;
    }

    .ant-picker .anticon {
      display: flex;
      align-items: center;
    }

    textarea {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    h1.header-title {
      font-weight: 700;
      font-size: calc(@title-text-size);
    }

    h2.header-sub-title {
      font-size: calc(@normal-text-size);
      font-weight: 700;
      margin-bottom: 8px;
    }

    h1.title {
      font-weight: 700;
      font-size: @title-text-size;
    }

    h1.title {
      font-weight: 700;
      font-size: calc(@title-text-size - 2px);
    }

    .sub-title {
      font-size: calc(@normal-text-size - 2px);
    }

    .code {
      text-align: right;
      margin-bottom: 2px;
      font-size: 22px;
      font-style: italic;
    }
    &-header {
      margin: auto;
    }

    &-main-content {
      padding: 0;
    }

    .title-paragraph {
      text-indent: @indentation;
    }

    .title-item {
      margin-bottom: 0;
    }

    .content-item {
      padding: 0 0 0 @indentation;
      margin-bottom: 0;
    }

    .classify-create-file-current-place-date {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }

    .classify-create-file-current-place {
      margin-right: 12px;
      width: 240px;
      border: 1px solid #d9d9d9;
    }
    .classify-create-file-current-date {
      width: 240px;
    }

    .bf-container {
      border: 1px solid #d9d9d9;
    }

    .first-editor,
    .second-editor {
      .bf-content {
        height: 150px;
      }
    }

    .third-editor {
      .bf-content {
        height: 200px;
      }
    }
    .first-sign {
      input {
        max-width: 210px;
      }
    }

    .mr {
      margin-right: 5px;
    }
    .mt-8 {
      margin-top: 8px;
    }
    .mb-8 {
      margin-bottom: 8px;
    }
    .mt-4 {
      margin-top: 4px;
    }
    .mb-4 {
      margin-bottom: 4px;
    }
    .mb-20 {
      margin-bottom: 20px;
    }

    // @media (max-width: 1199px) {
    //   h1,
    //   h2,
    //   h3,
    //   span,
    //   p,
    //   input,
    //   textarea {
    //     font-size: calc(@normal-text-size - 2px);
    //     line-height: calc(@normal-text-size);
    //   }
    //   h1.header-title {
    //     font-size: calc(@title-text-size - 2px);
    //   }

    //   h2.header-sub-title {
    //     font-size: calc(@normal-text-size + 1px);
    //   }

    //   h1.title {
    //     font-size: calc(@title-text-size - 2px);
    //   }
    //   .content-item {
    //     padding: 0 calc(@indentation - 36px) 0 calc(@indentation - 36px);
    //     margin-bottom: 0;
    //   }
    //   .title-paragraph {
    //     text-indent: calc(@indentation - 36px);
    //   }
    // }
  }
}

.category-document-form-wrapper {
  .category-document-form {
    margin: auto;
    // width: 1080px;

    @media screen and (min-width: 1600px) {
      width: 1024px !important;
    }

    @media (max-width: 1599px) {
      width: 90% !important;
    }

    @media (max-width: 1365px) {
      width: 100% !important;
    }

    h1,
    h2,
    h3,
    span,
    p,
    input,
    textarea {
      font-size: @normal-text-size;
      line-height: calc(@normal-text-size + 8px);
      margin-bottom: 8px;
    }

    &-header {
      margin: auto;
    }

    .ant-picker input::-webkit-input-placeholder {
      font-size: 18px;
      font-family: Roboto;
    }

    input,
    .ant-picker {
      padding: 2px 2px 2px 4px;
      margin-bottom: 0;
    }

    .ant-picker .ant-picker-suffix {
      display: flex;
      width: 18px;
    }

    .ant-picker span {
      margin: 0;
    }

    .ant-picker .anticon {
      display: flex;
      align-items: center;
    }

    textarea {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    h1.header-title {
      font-weight: 700;
      font-size: calc(@title-text-size);
    }

    h2.header-sub-title {
      font-size: calc(@normal-text-size);
      font-weight: 700;
      margin-bottom: 8px;
    }

    // p {
    //   text-indent: 32px !important;
    // }

    h1.title {
      font-weight: 700;
      font-size: calc(@title-text-size);
      margin-bottom: 16px;
      margin-top: 16px;
      text-indent: 32px;
    }

    .sub-title {
      font-size: calc(@normal-text-size);
      text-indent: 32px;
    }
  }
}

#form-docs {
  color: #222227;
  font-size: 16px;
  line-height: 22px;

  .mg-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .border-bottom-bold {
    border-bottom: 2px solid #222227;
  }
  .number-form-title {
    label {
      font-size: 18px;
    }
  }
  .content {
    .block-content {
      margin-bottom: 16x;
    }
    p {
      margin-bottom: 8px;
      font-size: 16px !important;

      &.input-form {
        max-width: 400px;
      }
      .ant-form-item {
        margin-bottom: 16px;
      }
    }
    .ant-form-item {
      margin-bottom: 8px;
      .ant-form-item-label label {
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
  .footer {
    p {
      margin-bottom: 0px;
      &.sign {
        margin-bottom: 12px;
      }
    }
    .date-asign {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }

  &.form-docs-history {
    .content {
      margin-bottom: 24px;
      ol {
        padding-left: 10px;
        &.upper-roman > li {
          &::marker {
            font-size: 18px;
          }
        }
        li {
          font-size: 16px;
          line-height: 22px;
        }
      }
      p {
        &.title {
          font-size: 18px;
          line-height: 22px;
        }
        &.form-box {
          display: inline-flex;
          gap: 4px;
          align-items: center;
          .ant-form-item {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

#table-form {
  .editable-cell-value-wrap {
    white-space: pre-wrap;
    padding: 0 !important;
  }
  .ant-table-cell {
    vertical-align: middle;
  }
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.flex-col {
  flex-direction: column;
}
