.font-size(@i) {
  .font-@{i} {
    font-size: @i + 0px;
    line-height: @i + 4px;
  }
}

.font-size(12);
.font-size(14);
.font-size(16);
.font-size(18);
.font-size(20);
.font-size(24);
.font-size(26);
.font-size(28);

.font-weight(@i) {
  .font-weight-@{i} {
    font-weight: @i;
  }
}

.font-weight(400);
.font-weight(600);
.font-weight(800);

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}
