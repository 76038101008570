// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the @spacers map, should you need more variation.

@spacer: 1rem;
@spacers: {
  0: 0;
  1: (@spacer * 0.25);
  2: (@spacer * 0.5);
  3: @spacer;
  4: (@spacer * 1.5);
  5: (@spacer * 3);
};

// This variable affects the `.h-*` and `.w-*` classes.
@sizes: {
  20: 20%;
  25: 25%;
  50: 50%;
  75: 75%;
  100: 100%;
  auto: auto;
};

.width-unset {
  width: unset;
}

.width-fit {
  width: fit-content;
}

.gen-width-variable(@prefix, @length, @i: 0) when (@i =< @length) {
  .width-@{i}@{prefix} {
    width: ~"@{i}%" !important;
  }
  .gen-width-variable(@prefix,@length, @i + 5);
}

.gen-width-variable(~"", 100);

@media (max-width: calc(@screen-sm-min - 1px)) {
  .gen-width-variable(-sm, 100);
}

@media (max-width: calc(@screen-md-min - 1px)) {
  .gen-width-variable(-md, 100);
}

@media (max-width: calc(@screen-lg-min - 1px)) {
  .gen-width-variable(-lg, 100);
}

@media (max-width: calc(@screen-xl-min - 1px)) {
  .gen-width-variable(-xl, 100);
}
