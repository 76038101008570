#archives_container {
  // .ant-table-fixed-header .ant-table-body::-webkit-scrollbar {
  //   display: none;
  // }
  // top ==========>>>>>>>>>>>>>>>>>>>
  .bd-top {
    border: 1px solid #e6e6e6;
    background-color: #fff;
    padding: 12px 20px 20px 20px;
    border-radius: 5px;
  }
  .mgr {
    margin-right: 20px;
  }

  //bottom -table ===========>>>>>>>>>>>>

  #bottom {
    .gutter-row .ant-card {
      border-radius: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
      border-radius: 0px 0px 10px 10px !important;
      // height: 500px;
    }
    .bdw {
      border: 1px solid #e6e6e6;
      border-bottom: 0px;
      border-radius: 10px 10px 5px 5px;
      // height: "50vh";
    }

    .ant-table-thead > tr > th {
      height: 0px;
      padding: 0;
      border: 0;
    }

    .ant-table-tbody tr.ant-table-row td:first-child {
      padding: 10px;
    }

    .ant-table-tbody tr.ant-table-row td {
      padding: 10px 0 10px 0;
    }

    .ant-table-tbody tr.ant-table-row td .ant-tag {
      margin: 0;
    }

    .ant-table-tbody tr.ant-table-row:hover td a,
    .ant-table-tbody tr.ant-table-row.active td a {
      color: #fff;
    }

    .ant-table-tbody tr.ant-table-row:hover td,
    .ant-table-tbody tr.ant-table-row.active td {
      background-color: #abd2fa !important;
      padding: 10px 0 10px 0;
      color: #fff;
    }
    .ant-table-tbody tr.ant-table-row:hover td:first-child,
    .ant-table-tbody tr.ant-table-row.active td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 10px;
    }
    .ant-table-tbody tr.ant-table-row:hover td:last-child,
    .ant-table-tbody tr.ant-table-row.active td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .loadCenter {
      height: 320px;
      margin: 40px 0;

      text-align: center;
      background: #fff;
      border-radius: 4px;
    }
  }

  .align {
    display: flex;
    justify-content: center;
  }
}
//modal =====================>>>>>>>>>>>>
.warehouse_modal {
  .ant-modal-footer {
    padding: 0px;
    border-color: #fff !important;
  }

  .ant-modal-footer .ant-btn-primary {
    text-shadow: none;
  }

  .ant-modal-footer .ant-btn {
    background-color: #fff !important;
    color: #fff !important;
    border-color: #fff !important;
    box-shadow: none !important;
  }

  .ant-table-fixed-header .ant-table-body::-webkit-scrollbar {
    display: none;
  }
}
#formArchives {
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: #000 !important;
  }
  .ant-input[disabled] {
    color: #000;
    font-weight: 300;
  }

  .ant-input:placeholder-shown {
    font-weight: 300;
  }
}
