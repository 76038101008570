// Date picker
// .ant-picker {
//   @border-radius: @input-border-radius;
// }

.ant-modal {
  .ant-picker-clear {
    background-color: @white;
  }
}

// Selector
#group-picker-left {
  & .ant-select {
    .ant-select-selector {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

#group-picker-center {
  & .ant-select {
    .ant-select-selector {
      border-radius: 0 !important;
    }
  }
}

#group-picker-right {
  & .ant-select {
    .ant-select-selector {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
