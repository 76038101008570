.ant-table-wrapper {
  .ant-table-thead > tr > th {
    background-color: #DD0000;
    color: #fff;
  }

  .ant-table-content {
    overflow-x: auto;
  }

  &.header-white {
    .ant-table-thead {
      > tr > th {
        background-color: @white;
        border-bottom-width: 2px;
      }
    }
  }

  .ant-table-tbody {
    > tr > th,
    > tr > td {
      vertical-align: baseline;
    }
  }

  // No Column
  &.no-header {
    .ant-table-thead {
      display: none;
    }
  }

  &.no-border {
    .ant-table-container table > thead > tr:last-child th:first-child {
      border-bottom-left-radius: @border-radius-base;
    }
    .ant-table-container table > thead > tr:last-child th:last-child {
      border-bottom-right-radius: @border-radius-base;
    }
    .ant-table-cell {
      border-bottom-width: 0px;
    }
  }

  & .with-border {
    .ant-table {
      border: 1px solid rosybrown !important;
    }
  }

  &.striped {
    .ant-table-tbody {
      tr:nth-child(even) {
        th,
        td {
          background-color: #f3f7ff;
        }
      }
    }
  }
}
