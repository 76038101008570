.ant-form-item {
  .ant-form-item-label {
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
      &::before {
        content: "";
        display: none;
      }

      &::after {
        content: "(*)";
        display: inline-block;
        color: var(--required-color);
        margin-left: 0.3rem;
      }
    }
  }

  > .ant-form-item-label label {
    font-weight: bold;
  }
}
