.ant-checkbox {
  &-inner {
    background-color: darken(@light-color, 5%);
    border-color: darken(@light-color, 5%);
  }

  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #dd0000;
      border-color: #dd0000;
    }
  }
  &-indeterminate &-inner {
    background-color: #dd0000;
    border-color: #dd0000;

    &::after {
      background-color: darken(@light-color, 5%);
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #dd0000;
}
